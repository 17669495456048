/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import { Map } from "immutable"
import moment from "moment"
import * as Consts from "./constants"
import { hasMockedId } from "./flow_typed_helpers"

export const hasErrors = (shift) =>
  !!shift.get("start_error") ||
  !!shift.get("break_start_error") ||
  !!shift.get("break_finish_error") ||
  !!shift.get("finish_error")

export const hasTimes = (shift) =>
  shift.get("start").isValid() || shift.get("finish").isValid() || hasAnyBreaksWithContent(shift)

export const hasAllowances = (shift) => shift.get("shift_allowances").size > 0

export const hasLeave = (shift) => !!shift.get("leave_request_id")

export const hasValidTimes = (shift) =>
  shift.get("start").isValid() && shift.get("finish").isValid() && hasAllBreaksValid(shift)

export const isApproved = (shift) => shift.get("status") === Consts.Status.Approved

export const isAutoApproved = (shift) => shift.get("auto_approved") === true

export const isPending = (shift) => shift.get("status") === Consts.Status.Pending

export const isExported = (shift) => shift.get("status") === Consts.Status.Exported

export const isNewRecord = (shift) => hasMockedId(shift.get("id"))

export const conflictingShift = (shift) => shift.get("conflicting_approved_shift") === true

export const LIMBER = "limber"
export const limberWorker = (shift) => shift.get("user").toJS().temporary_employee_type === LIMBER

/**
 * Shift has variance
 *
 * @param {Immutable.Map} shift
 */
export const shiftVariance = (shift) => {
  const shift_length = shift.get("shift_length") ? shift.get("shift_length") * 60 * 60 * 1000 : 0

  const rostered_length = shift.get("rostered_length") ? shift.get("rostered_length") * 60 * 60 * 1000 : 0

  const duration_difference = shift_length - rostered_length
  const duration = moment.duration(Math.ceil(duration_difference))

  const hoursDifference = duration ? duration.asHours() : 0

  const hours = hoursDifference ? Math.trunc(hoursDifference) : 0
  const minutes = hoursDifference ? Math.round((hoursDifference * 60) % 60) : 0

  const timeVariance = {
    hours,
    minutes,
  }

  const shift_cost = Math.round((shift.get("awarded_cost") + shift.get("auto_allowance_cost")) * 100) / 100

  const rostered_cost = Math.round(shift.get("rostered_cost" || 0) * 100) / 100

  const costVariance = rostered_cost - shift_cost

  return {
    hasVariance: Boolean(costVariance || timeVariance.hours, timeVariance.minutes),
    timeVariance,
    costVariance: costVariance,
  }
}
/**
 * we validate shifts for paycheck errors and this is stored in timesheet validations
 * this returns a boolean that determines if the shift can be approved
 * @param {Immutable.Map} shift to get the validation results
 * @param {Immutable.Map} timesheet to retrieve all validations
 * @returns {boolean} whether shift is approvable
 */
export const passedValidations = (shift, timesheet) => {
  const validations = timesheet.get("validations")
  if (!validations) return true

  const shiftValidation = validations.get(shift.get("id").toString())
  if (!shiftValidation) return true

  const shiftValidationResults = shiftValidation.get("results")
  return !shiftValidationResults.some((validation) => validation.get("blocking") && !validation.get("passed"))
}

/**
 * shift is auto approved if it is approved and is auto approveable
 *
 * @param {Immutable.Map} shift
 */
export const isApprovedAndAutoApproved = (shift) => isApproved(shift) && isAutoApproved(shift) && !hasLeave(shift)

/**
 * shift is clocked if it has any associated clocked
 * times
 *
 * @param {Immutable.Map} shift
 */
export const isClocked = (shift) =>
  shift.get("clocked_start").isValid() || shift.get("clocked_finish").isValid() || anyValidClockedBreak(shift)

/**
 * shift is rostered if it has any associated clocked
 * times
 *
 * @param {Immutable.Map} shift
 */
export const isRostered = (shift) => shift.get("rostered_start").isValid() || shift.get("rostered_end").isValid()

/**
 * a shift is approvable as long as it has *no* errors,
 * any allowances or leave or has valid times,
 * @param {Immutable.Map} shift to check is approvable
 * @returns {boolean} whether shift is approvable
 */
export const isApprovable = (shift) =>
  !hasErrors(shift) &&
  !hasOverlappingBreaks(shift) &&
  !conflictingShift(shift) &&
  ((hasAllowances(shift) && !hasTimes(shift)) || hasLeave(shift) || hasValidTimes(shift))

/**
 * @param {Shift} a shift may be counted as empty if it is:
 * - mocked (negative id)
 * - no times && no manual allowances or nullified shift allowance value
 */
export const isEmpty = (shift) =>
  hasMockedId(shift.get("id")) ||
  (!shift.get("start").isValid() &&
    !shift.get("finish").isValid() &&
    shift.get("shift_allowances").every((s) => s.get("automated") || s.get("value") === 0) &&
    hasAllBreaksEmpty(shift))

/**
 * @param {Shift} a shift may be counted as having all breaks empty if:
 * - it has no breaks
 * - all breaks have no times
 * - all break lengths are null/0
 */
export const hasAllBreaksEmpty = (shift) =>
  shift.get("breaks").size === 0 || shift.get("breaks").every((sb) => breakIsEmpty(sb))

const breakIsEmpty = (sb) =>
  !sb.get("start").isValid() && !sb.get("finish").isValid() && (sb.get("length") == null || sb.get("length") === 0)

export const breakHasEmptyInputs = (sb) =>
  !sb.get("start").isValid() || !sb.get("finish").isValid() || sb.get("length") == null || sb.get("length") === 0

/**
 * @param {Shift} all breaks on the shift are either fully empty, or are valid
 *                ie. no breaks are in an incomplete state
 *                if true, we can probably approve the shift!
 */
export const hasAllBreaksValid = (shift) => shift.get("breaks").every((sb) => breakIsValid(sb))

export const hasOverlappingBreaks = (shift) => {
  const breaks = shift.get("breaks")
  if (breaks.size <= 1) {
    return false
  }
  return breaks.some((currentBreak) => {
    const currentBreakStart = new Date(currentBreak.get("start"))
    const currentBreakFinish = new Date(currentBreak.get("finish"))
    return breaks.some((otherBreak) => {
      const otherBreakStart = new Date(otherBreak.get("start"))
      const otherBreakFinish = new Date(otherBreak.get("finish"))
      return (
        (otherBreakStart > currentBreakStart && otherBreakStart < currentBreakFinish) ||
        (otherBreakFinish > currentBreakStart && otherBreakFinish < currentBreakFinish)
      )
    })
  })
}

export const breakIsValid = (sb) =>
  (sb.get("start").isValid() && sb.get("finish").isValid()) || // start & finish both valid
  (!sb.get("start").isValid() && !sb.get("finish").isValid()) // start & finish both blank

/**
 * @param {Shift} any breaks on the shift have content besides length for edit validation
 */
export const hasAnyBreakContentToDisableEditLength = (shift) =>
  shift.get("breaks").some((sb) => sb.get("start").isValid() || sb.get("finish").isValid())

/**
 * @param {Shift} any breaks on the shift have content
 */
const hasAnyBreaksWithContent = (shift) =>
  shift.get("breaks").some((sb) => sb.get("start").isValid() || sb.get("finish").isValid() || sb.get("length"))

/**
 * @param {Shift} any breaks on the shift have been clocked
 */
const anyValidClockedBreak = (shift) =>
  shift.get("breaks").some((sb) => sb.get("clocked_start").isValid() || sb.get("clocked_finish").isValid())

export const hasNoCosts = (shift) => isEmpty(shift) && !isRostered(shift) && !isClocked(shift)

export const shouldShowCollapsedOnLoad = (shift) => isEmpty(shift) && !isRostered(shift)

export const breakIsManual = (shiftBreak) =>
  !!shiftBreak && shiftBreak.get("break_type") === Consts.ShiftBreakType.USER_ENTERED

/**
 * Finds all parameters in a shift that are not primed to be saved (in a cached request)
 * @param {*} shift to be checked
 * @param {*} requestCaches all currently cached requests
 */
export const validEffects = (shift, requestCaches) => {
  const cache = requestCaches.get(shift.id, new Map())

  return Object.keys(shift).filter((key) => !Consts.INVALID_EFFECTS.includes(key) && !cache.has(key))
}

/**
 * Check if the day has multiple shifts only to one staff use only in salary costing
 * @param {Immutable.Map} shift to be checked
 * @param {Immutable.OrderedMap} shifts all shifts on the timesheet
 * @returns {boolean} whether shift for the day is multiple or not
 */
export const isMultipleShift = (shift, shifts = []) => {
  const filteredShifts = shifts.filter(
    (s) => s.get("date") === shift.get("date") && s.get("user_id") === shift.get("user_id")
  )

  return filteredShifts.size > 1
}
