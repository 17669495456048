// @flow

import * as React from "react"
import cn from "classnames"
import { t as globalT } from "helpers/i18n"
import styles from "./styles.module.scss"

const t = (key, ...args) => globalT(`js.timesheets.shift_card.shift_checks.${key}`, ...args)

type ValidationResult = {|
  blocking: boolean,
  class: string,
  passed: boolean,
  type: string,
|}

type Props = {|
  loading: boolean,
  results: Array<ValidationResult>,
|}

export default function ShiftChecks({ loading, results }: Props): React.Node {
  const passingChecks = results.filter((result) => result.passed)
  const failedChecks = results.filter((result) => !result.passed)
  const blocking = failedChecks.some((result) => result.blocking)

  return (
    <div className={cn(styles.container, loading && styles.loading, blocking ? styles.failed : styles.succeeded)}>
      <details open={failedChecks.length > 0}>
        <summary className={styles.summary}>
          {blocking
            ? t("not_ready_for_payroll_title", { passing: passingChecks.length, total: results.length })
            : t("ready_for_payroll_title", { passing: passingChecks.length, total: results.length })}
        </summary>

        {failedChecks.length === 0 ? (
          <p className={styles.fullyPassedMessage} data-testid="no-failures-message">
            {t("fully_passed")}
          </p>
        ) : (
          <ul className={styles.results} data-testid="failure-list">
            {failedChecks.map((check) => (
              <li
                className={cn(styles.result, check.blocking ? styles.failure : styles.warning)}
                data-testid={check.blocking ? "failure" : "warning"}
                key={check.type}
              >
                <div className={cn(styles.indicator)} />
                <span>{t(`validation_titles.${check.class}.${check.type}`)}</span>
              </li>
            ))}
          </ul>
        )}
      </details>
    </div>
  )
}
