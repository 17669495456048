/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import { compact } from "underscore" // eslint-disable-line underscore-to-lodash/prefer-import-lodash

import * as Shift from "timesheets/models/shift"
import * as Timesheet from "timesheets/models/timesheet"
import * as Navigation from "helpers/navigation"
import * as Routes from "helpers/routes"
import Request from "helpers/request"

import Actions from "../actions"

/**
 * Validates the status of a timesheet, and if it has changed
 * it updates it.
 */
export const timesheetsValidateStatus =
  ({ timesheet }) =>
  (dispatch, getState) => {
    const { shifts } = getState()

    const hasPendingShift = Shift.somePending(shifts)

    const nextTimesheetStatus = hasPendingShift ? Timesheet.Status.Pending : Timesheet.Status.Approved

    if (nextTimesheetStatus === timesheet.get("status")) {
      return
    }

    return dispatch(
      Actions.timesheetsMerge({
        id: timesheet.get("id"),
        data: { status: nextTimesheetStatus },
      })
    )
  }

/**
 * validates a timesheets shifts and dispatches errors accordingly
 * @param {Object} [$0={}] options for validation
 * @param {string} $0.date to filter shifts by
 */
export const timesheetsValidateChronology =
  ({ date, userId } = {}) =>
  (dispatch, getState) => {
    const filters = compact([date && Shift.filterByNearDate(date), userId && Shift.filterByUserId(userId)])

    const shifts = filters.reduce((acc, filter) => filter(acc), getState().shifts)

    // if either the shift or the time ctx have an error we want to
    Shift.validateTimes(shifts.sort(Shift.sorter)).forEach((tc) => {
      // type tc = {
      if (tc.error || shifts.getIn([tc.id, `${tc.field}_error`])) {
        //   id: number,
        dispatch(
          Actions.shiftsMergeIn({
            //   time: moment,
            id: tc.id, //   field: string,
            shift: { [`${tc.field}_error`]: tc.error }, //   error?: ErrorContext,
          })
        ) // }
      }
    })
  }

/**
 * deletes the timesheet of `id` with tanda and then if successful,
 * loads the timesheets list
 * @param {number} id of timesheet to delete
 */
export const timesheetsDelete =
  ({ timesheet }) =>
  (dispatch) => {
    dispatch(
      Actions.timesheetsMerge({
        id: timesheet.get("id"),
        data: { state: Timesheet.States.Syncing },
      })
    )

    return Request.delete(Routes.timesheet_path(timesheet.get("id")))
      .then(() => Navigation.goto(Routes.timesheets_path()))
      .catch(() => {
        dispatch(
          Actions.timesheetsMerge({
            id: timesheet.get("id"),
            data: { state: Timesheet.States.Clean },
          })
        )
        dispatch(Actions.userMerge({ error: "sync" }))
      })
  }

/**
 * locally updates the state of all shifts that are approvable, and
 * if timesheet is approvable it also approves it. finally it syncs this state
 * with tanda.
 *
 * @param {number} nextId to load if the user would like to move on
 */
export const timesheetsApprove =
  ({ timesheet, nextId }) =>
  (dispatch, getState) => {
    // approve all approvable shifts
    const state = getState()
    const shiftVarianceApprovalRequired = state.currentOrganisation?.get("shift_variance_approval_required")

    state.shifts
      .filter(
        (s) =>
          Shift.isApprovable(s) &&
          (shiftVarianceApprovalRequired ? !Shift.shiftVariance(s).hasVariance : true) &&
          Shift.passedValidations(s, timesheet)
      )
      .forEach((shift) =>
        dispatch(
          Actions.shiftsMergeIn({
            id: shift.get("id"),
            shift: { status: Shift.Status.Approved },
          })
        )
      )

    // if there are any left that are pending
    const hasPendingShift = Shift.somePending(getState().shifts)

    dispatch(
      Actions.timesheetsMerge({
        id: timesheet.get("id"),
        data: {
          state: Timesheet.States.Approving,
          status: hasPendingShift ? Timesheet.Status.Pending : Timesheet.Status.Approved,
        },
      })
    )

    // sync timesheet approval, and if approved go to next timesheet at id (if it exists)
    return dispatch(
      Actions.timesheetsSyncApprove({
        timesheet,
        nextId: hasPendingShift ? NaN : nextId,
      })
    )
  }

/**
 * locally updates the state of all shifts and the timesheet
 * to pending. finally it syncs this state
 * with tanda.
 */
export const timesheetsUnapprove =
  ({ timesheet }) =>
  (dispatch, getState) => {
    getState().shifts.forEach((shift) =>
      dispatch(
        Actions.shiftsMergeIn({
          id: shift.get("id"),
          shift: { status: Shift.Status.Pending },
        })
      )
    )

    dispatch(
      Actions.timesheetsMerge({
        id: timesheet.get("id"),
        data: {
          state: Timesheet.States.Unapproving,
          status: Timesheet.Status.Pending,
        },
      })
    )

    return dispatch(Actions.timesheetsSyncUnapprove({ timesheet }))
  }

/**
 * sends a request to tanda to approve timesheet
 *
 * @param {number} nextId to load if the user would like to move on
 */
export const timesheetsSyncApprove =
  ({ timesheet, nextId }) =>
  (dispatch, getState) => {
    const reqPromise = Request.put(Routes.approve_timesheet_path(timesheet.get("id")))

    return reqPromise
      .then(({ data }) => {
        if (nextId) {
          Navigation.goto(Routes.timesheet_path(nextId))
        } else {
          dispatch(
            Actions.timesheetsMerge({
              id: data.timesheet.id,
              data: { ...data.timesheet, state: Timesheet.States.Clean },
            })
          )
        }
      })
      .catch(() => {
        dispatch(
          Actions.timesheetsMerge({
            id: timesheet.get("id"),
            data: { state: Timesheet.States.Clean },
          })
        )
        dispatch(Actions.userMerge({ error: "sync" }))
      })
  }

/**
 * sends a request to tanda to unapprove timesheet
 */
export const timesheetsSyncUnapprove =
  ({ timesheet }) =>
  (dispatch, getState) =>
    Request.put(Routes.unapprove_timesheet_path(timesheet.get("id")))
      .then(() =>
        dispatch(
          Actions.timesheetsMerge({
            id: timesheet.get("id"),
            data: { state: Timesheet.States.Clean },
          })
        )
      )
      .catch(() => {
        dispatch(
          Actions.timesheetsMerge({
            id: timesheet.get("id"),
            data: { state: Timesheet.States.Clean },
          })
        )
        dispatch(Actions.userMerge({ error: "sync" }))
      })
